import { render, staticRenderFns } from "./ConstitutionHeader.vue?vue&type=template&id=3a4357f7&scoped=true&"
import script from "./ConstitutionHeader.vue?vue&type=script&lang=js&"
export * from "./ConstitutionHeader.vue?vue&type=script&lang=js&"
import style0 from "./ConstitutionHeader.vue?vue&type=style&index=0&id=3a4357f7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a4357f7",
  null
  
)

export default component.exports