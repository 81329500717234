var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"theme-page",style:({
    '--theme-color': ("var(--" + _vm.theme + ")"),
  })},[(_vm.$tvaMq === 'desktop')?_c('div',{style:({ display: 'flex' })},[_c('ConstitutionCard',{staticClass:"theme-page__side-nav",style:({
        '--card-border': "var(--basic-white)",
      }),attrs:{"gradientColor":"var(--basic-black)","vertical":""},on:{"onCardClick":function($event){return _vm.$router.push({ name: 'themes' })}}},[_vm._v(" "+_vm._s(_vm.content.ctas.back)+" ")]),_c('div',{staticClass:"theme-page__wrapper"},[_c('img',{staticClass:"theme-page__title",style:({
          '--title-width': _vm.themeLogoWidths[_vm.theme] * 100 + 'vw',
        }),attrs:{"src":(_vm.$baseUrl + "images/themes/text-" + _vm.theme + "-desktop.svg"),"alt":_vm.themes[_vm.theme]}}),_c('div',{staticClass:"theme-page__paragraphs"},_vm._l((_vm.content.texts[_vm.theme]),function(paragraph,i){return _c('p',{key:'themetext_' + i,staticClass:"theme-page__paragraphs__paragraph",domProps:{"innerHTML":_vm._s(paragraph)}})}),0)]),_c('div',{staticClass:"theme-page__wrapper right"},[_c('img',{staticClass:"theme-page__illustration",attrs:{"src":(_vm.$baseUrl + "images/themes/illustration-" + _vm.theme + "-desktop.svg"),"alt":""}}),(_vm.$tvaMq !== 'desktop')?_c('img',{staticClass:"theme-page__kpis",attrs:{"src":(_vm.$baseUrl + "images/themes/kpi-" + _vm.theme + ".svg"),"alt":""}}):_vm._e(),_c('div',{staticClass:"theme-page__rounds"},_vm._l((_vm.content.kpis[_vm.theme]),function(kpi){return _c('KPIAnimated',{key:kpi.value + kpi.text,attrs:{"value":kpi.value,"text":kpi.text}})}),1),_c('ConstitutionCard',{staticClass:"theme-page__viz-button",style:({
          '--card-color': ("var(--" + _vm.theme + "-dark)"),
          '--card-border': "var(--basic-dark-grey)",
          '--dog-ear-bg': ("var(--" + _vm.theme + ")"),
          '--dog-ear-bg-inner': ("var(--" + _vm.theme + "-dark)"),
        }),attrs:{"gradientColor":"var(--basic-black)"},on:{"onCardClick":_vm.toFilteredViz}},[_c('div',{staticClass:"theme-page__viz-button__title"},[_vm._v(" "+_vm._s(_vm.content.themeToViz.title)+" ")]),_c('div',{staticClass:"theme-page__viz-button__cta",domProps:{"innerHTML":_vm._s(_vm.content.themeToViz.cta)}})])],1)],1):_vm._e(),(_vm.$tvaMq !== 'desktop')?_c('ConstitutionCard',{staticClass:"theme-page__side-nav",style:({
      '--card-border': "var(--basic-white)",
    }),attrs:{"gradientColor":"var(--basic-black)","vertical":""},on:{"onCardClick":function($event){return _vm.$router.push({ name: 'themes' })}}},[_vm._v(" "+_vm._s(_vm.content.ctas.back)+" ")]):_vm._e(),_c('ConstitutionHeader',{staticClass:"theme-page__header"}),_c('ConstitutionHeader',{staticClass:"theme-page__header",attrs:{"theme":true}}),(_vm.$tvaMq !== 'desktop')?_c('img',{staticClass:"theme-page__title",style:({
      '--title-width': _vm.themeLogoWidths[_vm.theme] * 100 + 'vw',
    }),attrs:{"src":(_vm.$baseUrl + "images/themes/text-" + _vm.theme + ".svg"),"alt":_vm.themes[_vm.theme]}}):_vm._e(),(_vm.$tvaMq !== 'desktop')?_c('div',{staticClass:"theme-page__paragraphs"},_vm._l((_vm.content.texts[_vm.theme]),function(paragraph,i){return _c('p',{key:'themetext_' + i,staticClass:"theme-page__paragraphs__paragraph",domProps:{"innerHTML":_vm._s(paragraph)}})}),0):_vm._e(),(_vm.$tvaMq !== 'desktop')?_c('img',{staticClass:"theme-page__kpis",attrs:{"src":(_vm.$baseUrl + "images/themes/kpi-" + _vm.theme + ".svg"),"alt":""}}):_vm._e(),(_vm.$tvaMq !== 'desktop')?_c('img',{staticClass:"theme-page__illustration",attrs:{"src":(_vm.$baseUrl + "images/themes/illustration-" + _vm.theme + ".svg"),"alt":""}}):_vm._e(),(_vm.$tvaMq !== 'desktop')?_c('ConstitutionCard',{staticClass:"theme-page__viz-button",style:({
      '--card-color': ("var(--" + _vm.theme + "-dark)"),
      '--card-border': "var(--basic-dark-grey)",
      '--dog-ear-bg': ("var(--" + _vm.theme + ")"),
      '--dog-ear-bg-inner': ("var(--" + _vm.theme + "-dark)"),
    }),attrs:{"gradientColor":"var(--basic-black)","vertical":false},on:{"onCardClick":_vm.toFilteredViz}},[_c('div',{staticClass:"theme-page__viz-button__title"},[_vm._v(" "+_vm._s(_vm.content.themeToViz.title)+" ")]),_c('div',{staticClass:"theme-page__viz-button__cta",domProps:{"innerHTML":_vm._s(_vm.content.themeToViz.cta)}})]):_vm._e(),_c('Transition',{attrs:{"name":"fade"}},[(_vm.getModalState)?_c('ModalSocialShare'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }