<template>
  <main
    class="theme-page"
    :style="{
      '--theme-color': `var(--${theme})`,
    }"
  >
    <div v-if="$tvaMq === 'desktop'" :style="{ display: 'flex' }">
      <ConstitutionCard
        class="theme-page__side-nav"
        gradientColor="var(--basic-black)"
        vertical
        :style="{
          '--card-border': `var(--basic-white)`,
        }"
        @onCardClick="$router.push({ name: 'themes' })"
      >
        {{ content.ctas.back }}
      </ConstitutionCard>
      <div class="theme-page__wrapper">
        <img
          class="theme-page__title"
          :src="`${$baseUrl}images/themes/text-${theme}-desktop.svg`"
          :alt="themes[theme]"
          :style="{
            '--title-width': themeLogoWidths[theme] * 100 + 'vw',
          }"
        />
        <div class="theme-page__paragraphs">
          <p
            v-for="(paragraph, i) of content.texts[theme]"
            :key="'themetext_' + i"
            class="theme-page__paragraphs__paragraph"
            v-html="paragraph"
          />
        </div>
      </div>
      <div class="theme-page__wrapper right">
        <img
          class="theme-page__illustration"
          :src="`${$baseUrl}images/themes/illustration-${theme}-desktop.svg`"
          alt=""
        />
        <img
          class="theme-page__kpis"
          :src="`${$baseUrl}images/themes/kpi-${theme}.svg`"
          alt=""
          v-if="$tvaMq !== 'desktop'"
        />
        <div class="theme-page__rounds">
          <KPIAnimated
            v-for="kpi in content.kpis[theme]"
            :key="kpi.value + kpi.text"
            :value="kpi.value"
            :text="kpi.text"
          />
        </div>

        <ConstitutionCard
          class="theme-page__viz-button"
          :style="{
            '--card-color': `var(--${theme}-dark)`,
            '--card-border': `var(--basic-dark-grey)`,
            '--dog-ear-bg': `var(--${theme})`,
            '--dog-ear-bg-inner': `var(--${theme}-dark)`,
          }"
          gradientColor="var(--basic-black)"
          @onCardClick="toFilteredViz"
        >
          <div class="theme-page__viz-button__title">
            {{ content.themeToViz.title }}
          </div>
          <div
            class="theme-page__viz-button__cta"
            v-html="content.themeToViz.cta"
          />
        </ConstitutionCard>
      </div>

      <!-- <div>
        <img
          class="theme-page__title"
          :src="`${$baseUrl}images/themes/text-${theme}.svg`"
          :alt="themes[theme]"
          :style="{
            '--title-width': themeLogoWidths[theme] * 100 + 'vw',
          }"
        />
        <div class="theme-page__paragraphs">
          <p
            v-for="(paragraph, i) of content.texts[theme]"
            :key="'themetext_' + i"
            class="theme-page__paragraphs__paragraph"
            v-html="paragraph"
          />
        </div> -->
    </div>
    <ConstitutionCard
      class="theme-page__side-nav"
      gradientColor="var(--basic-black)"
      vertical
      :style="{
        '--card-border': `var(--basic-white)`,
      }"
      @onCardClick="$router.push({ name: 'themes' })"
      v-if="$tvaMq !== 'desktop'"
    >
      {{ content.ctas.back }}
    </ConstitutionCard>

    <ConstitutionHeader class="theme-page__header" />

    <ConstitutionHeader class="theme-page__header" :theme="true" />
    <!-- Title image -->
    <img
      class="theme-page__title"
      :src="`${$baseUrl}images/themes/text-${theme}.svg`"
      :alt="themes[theme]"
      :style="{
        '--title-width': themeLogoWidths[theme] * 100 + 'vw',
      }"
      v-if="$tvaMq !== 'desktop'"
    />

    <div class="theme-page__paragraphs" v-if="$tvaMq !== 'desktop'">
      <p
        v-for="(paragraph, i) of content.texts[theme]"
        :key="'themetext_' + i"
        class="theme-page__paragraphs__paragraph"
        v-html="paragraph"
      />
    </div>

    <img
      v-if="$tvaMq !== 'desktop'"
      class="theme-page__kpis"
      :src="`${$baseUrl}images/themes/kpi-${theme}.svg`"
      alt=""
    />
    <img
      v-if="$tvaMq !== 'desktop'"
      class="theme-page__illustration"
      :src="`${$baseUrl}images/themes/illustration-${theme}.svg`"
      alt=""
    />

    <ConstitutionCard
      v-if="$tvaMq !== 'desktop'"
      class="theme-page__viz-button"
      :style="{
        '--card-color': `var(--${theme}-dark)`,
        '--card-border': `var(--basic-dark-grey)`,
        '--dog-ear-bg': `var(--${theme})`,
        '--dog-ear-bg-inner': `var(--${theme}-dark)`,
      }"
      gradientColor="var(--basic-black)"
      :vertical="false"
      @onCardClick="toFilteredViz"
    >
      <div class="theme-page__viz-button__title">
        {{ content.themeToViz.title }}
      </div>
      <div
        class="theme-page__viz-button__cta"
        v-html="content.themeToViz.cta"
      />
    </ConstitutionCard>
    <Transition name="fade">
      <ModalSocialShare v-if="getModalState" />
    </Transition>
  </main>
</template>

<script>
import ConstitutionHeader from "../components/ConstitutionHeader.vue";
import ConstitutionCard from "../components/ConstitutionCard.vue";
import { mapActions, mapGetters } from "vuex";
import { enrichDataFunc } from "../utilities/functions/enrichData";
import KPIAnimated from "../components/KPIAnimated.vue";
import ModalSocialShare from "../components/ModalSocialShare.vue";

export default {
  components: {
    ConstitutionHeader,
    ConstitutionCard,
    KPIAnimated,
    ModalSocialShare,
  },
  data() {
    return {
      theme: null,
    };
  },
  created() {
    this.theme = this.$route.query.theme;
  },
  computed: {
    themeLogoWidths() {
      return this.$tvaMq === "desktop" || this.$tvaMq === "large "
        ? {
            tema_lavoro: 0.11,
            tema_minoranze: 0.15,
            tema_unità: 0.1,
            tema_uguaglianza: 0.175,
          }
        : {
            tema_lavoro: 0.35,
            tema_minoranze: 0.4,
            tema_unità: 0.41,
            tema_uguaglianza: 0.43,
          };
    },
    themes() {
      return this.$t("themes");
    },
    content() {
      return this.$t("themePage");
    },
    ...mapGetters(["filteredData", "getModalState"]),
  },
  methods: {
    toFilteredViz() {
      this.setSelectedLabel(this.theme);
      this.setHierarchyData(enrichDataFunc(this.filteredData).enrichedData);
      this.$router.push({ name: "constitution-page" });
    },
    ...mapActions(["setSelectedLabel", "setHierarchyData"]),
  },
  watch: {
    $route(to) {
      this.theme = to.query.theme;
    },
  },
};
</script>

<style lang="scss" scoped>
.theme-page {
  $viz-btn-width: 80%;

  background-color: var(--theme-color);
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);

  &__header {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__side-nav {
    @include title-h-4;

    --dog-ear-size: #{$side-nav-height};
    --card-color: var(--basic-white);

    width: $side-nav-height;
    height: 100%;
    position: fixed;
    align-items: center;
    text-transform: uppercase;

    ::v-deep .constitution-card__content {
      @include flip-text;

      padding-left: 0;
    }
  }

  &__title {
    width: var(--title-width);
    margin-top: toVW(144);
    margin-left: calc(#{toVW(106)} + #{$side-nav-height});
  }

  &__paragraphs {
    @include theme-paragraph;

    position: absolute;
    top: toVW(228);
    right: toVW(80);
    width: toVW(611);

    &__paragraph {
      margin-bottom: 3rem;
    }
  }

  &__kpis {
    position: absolute;
    right: toVW(113);
    bottom: toVW(400);
    width: toVW(305);
  }

  &__illustration {
    position: absolute;
    width: toVW(1019);
    bottom: toVW(210);
    left: calc((100% - #{$viz-btn-width} + #{$side-nav-height}) * 0.5);
    max-height: 45vh;
  }

  &__viz-button {
    position: absolute;
    bottom: toRem(60);
    width: $viz-btn-width;
    left: calc((100% - #{$viz-btn-width} + #{$side-nav-height}) * 0.5);
    text-align: center;
    background-color: var(--card-color);

    &__title,
    &__cta {
      position: relative;
      left: 50%;
    }

    &__title {
      @include title-h-3;

      font-weight: normal;
      text-transform: uppercase;
      margin-bottom: 0.25rem;
    }

    &__cta {
      @include button-xl;
    }
  }
}

.desktop {
  .theme-page {
    --text-wrapper: #{toRem(367)};
    --sidebar-width: #{toRem(67)};

    display: flex;

    &__rounds {
      margin-left: toRem(69);

      & > *:not(:first-child) {
        margin-top: toRem(40);
      }
    }

    &__wrapper {
      display: inline-block;
      width: var(--text-wrapper);
      margin-top: toRem(121);
      margin-left: toRem(110);
      flex-shrink: 0;

      &.right {
        margin-left: 0;
        display: flex;
        width: calc(100% - var(--text-wrapper) - var(--sidebar-width));
        justify-content: flex-end;
      }
    }

    &__side-nav {
      font-size: toRem(14);
      width: fit-content;
      height: 100%;
      height: 100vh;
      padding: toRem(70) 0;
      padding-right: toRem(20);

      border-radius: 0;
      filter: none;
      display: inline-block;

      & > .constitution-card__dog-ear {
        bottom: unset;
        top: 0;
      }
    }

    &__title {
      margin: 0;
      margin-bottom: toRem(37);
    }

    &__paragraphs {
      position: unset;
      width: fit-content;
      @include paragraph-s;

      &__paragraph {
        margin-bottom: 1.5rem;
      }
    }

    &__illustration {
      position: unset;
      width: toRem(551);
      max-width: 33vw;
      max-height: unset;
      margin-bottom: 4rem;
    }

    &__kpis {
      position: unset;
      width: toRem(170);
      height: fit-content;
      margin-left: toRem(8);
    }

    &__viz-button {
      position: absolute !important;
      bottom: toRem(39);
      right: 3.7rem;
      left: unset;
      width: 55%;
      filter: none;
      border: none;
      @include shadow-1;

      &__title,
      &__cta {
        position: unset;
        left: 50%;
      }

      &__title {
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
        letter-spacing: 0.96px;
        text-transform: uppercase;
        margin-bottom: toRem(8);
      }

      &__cta {
        font-family: "GT Super";
        font-size: toRem(30);
        font-style: normal;
        font-weight: 700;
        line-height: 90%; /* 27px */
        letter-spacing: 0.015em;
        ::v-deep em {
          font-style: normal;
        }
      }
    }
  }

  @media only screen and (max-height: 780px) {
    .theme-page__rounds {
      transform: scale(0.8);
    }
  }
}
</style>
