<template>
  <header :class="['constitution-header', { theme }]">
    <router-link
      v-for="link of content.links"
      :key="link.pathName"
      :class="[
        'constitution-header__link',
        {
          active:
            $route.name === link.pathName ||
            ($route.name === 'theme' && link.pathName === 'themes') ||
            $route.query.theme === link.pathName,
        },
      ]"
      :to="
        theme
          ? { name: 'theme', query: { theme: link.pathName } }
          : { name: link.pathName }
      "
    >
    <span>
      {{ link.label }}
    </span>
    </router-link>
    <button
      class="constitution-header__share"
      v-if="!theme && $tvaMq === 'desktop'"
      :aria-label="$t('general.ctas.share')"
      @click="() => setModalState(!getModalState)"
    >
      <svgicon name="share" height="20px" color="var(--basic-black)"></svgicon>
    </button>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["theme"],
  computed: {
    content() {
      return this.theme ? this.$t("headerTheme") : this.$t("header");
    },
    ...mapGetters(["getModalState"]),
  },
  methods: {
    ...mapActions(["setModalState"]),
  },
};
</script>

<style lang="scss" scoped>
.constitution-header {
  $header-margin: toRem(221);
  @include drop-shadow-1;

  height: $page-header;
  width: calc(100% - #{$header-margin} * 2);
  margin: 0 $header-margin;
  padding: 1rem toRem(45) 1.35rem;
  border: toRem(1) solid $basic-black;
  border-top: none;
  border-radius: 0 0 toRem(10) toRem(10);
  background-color: $basic-white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__link {
    @include title-h-3;
    flex-basis: calc(50% - 4rem);
    text-align: center;
    &:nth-child(1) {
      flex-basis: 3rem;
    }
    .theme & {
      flex-basis: auto;
    }

    text-transform: uppercase;

    &.active span {
      border-bottom: toRem(1) solid $basic-black;
    }
  }
}

.desktop {
  .constitution-header {
    $header-margin: 19.5%;
    width: calc(100% - #{$header-margin} * 2);
    margin: 0 $header-margin;
    padding: 0 toRem(56);
    @include shadow-1;
    height: toRem(50);
    border: none;
    filter: none;
    z-index: 2;

    &.theme {
      $header-margin: 30%;
      width: calc(100% - #{$header-margin} * 2);
      margin: 0 $header-margin;
      z-index: 1;
    }

    &.theme {
      margin-top: toRem(50);
    }

    &__link {
      &:hover span {
        border-bottom: toRem(1) solid $basic-black;
      }

      font-size: 1rem;
    }
  }

  @media only screen and (max-width: 1250px) {
    .constitution-header__link {
      font-size: 0.825rem;
    }
  }

  @media only screen and (max-width: 1150px) {
    .constitution-header__link {
      font-size: 0.725rem;
    }
  }
}
</style>
