var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'constitution-card',
    { vertical: _vm.vertical },
    { nonviz: !_vm.viz },
    { viz: _vm.viz },
    { navButton: _vm.navButton } ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('onCardClick')},"mousemove":function (e) {
      if (_vm.viz) { return _vm.$emit('onCardMove', e); }
    },"mouseleave":function (e) {
      if (_vm.viz) { return _vm.$emit('onCardLeave', e); }
    }}},[_c('div',{staticClass:"constitution-card__content"},[_vm._t("default")],2),_c('transition',{attrs:{"name":"fade"}},[((_vm.withDogEar && !_vm.viz) || _vm.navButton)?_c('DogEar',{staticClass:"constitution-card__dog-ear",attrs:{"vertical":_vm.vertical,"gradientColor":_vm.gradientColor,"showArrow":_vm.showArrow,"viz":_vm.viz}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }