<template>
  <div
    :class="[
      'dog-ear__wrapper',
      { viz: viz, hideShadow: hideShadow, whitePatchBlur: whitePatchBlur },
    ]"
  >
    <div
      :class="[
        'dog-ear',
        {
          'round-corners': roundCorners,
        },
        { vertical },
      ]"
      :style="{ '--color-2': gradientColor }"
    >
      <svgicon
        v-if="showArrow"
        :class="['dog-ear__icon', { large: largeArrow }]"
        :name="symbol ? 'close' : largeArrow ? 'medium-arrow' : 'small-arrow'"
        :color="`r-var(--basic-black)`"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    roundCorners: {
      type: Boolean,
      default: true,
    },
    gradientColor: {
      type: String,
      default: "var(--primary-dark)",
    },
    showArrow: {
      type: Boolean,
      default: true,
    },

    vertical: {
      type: Boolean,
      default: true,
    },
    viz: {
      type: Boolean,
      default: false,
    },
    hideShadow: {
      type: Boolean,
      default: false,
    },
    symbol: {
      type: String,
      required: false,
      default: null,
    },
    whitePatchBlur: {
      type: Boolean,
      required: false,
      default: true,
    },
    largeArrow: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.dog-ear__wrapper {
  position: relative;

  .desktop & {
    &::after {
      content: "";
      position: absolute;

      bottom: -20%;
      right: -20%;
      width: 120%;
      height: 120%;
      background: linear-gradient(
        -225deg,
        var(--dog-ear-bg-inner, var(--basic-white)) 40%,
        var(--dog-ear-bg, var(--basic-white)) 40%
      );
      z-index: 0;
      border-radius: toRem(10) 0;
    }
    &.whitePatchBlur {
      &::after {
        top: 0%;
        left: 0%;
        filter: blur(4px);
      }
    }
  }

  &.viz {
    &::after {
      display: none;
    }
  }
  &.hideShadow {
    &::after {
      display: initial;
    }
  }
}
.dog-ear {
  --max-size: #{toRem(50)};
  --size: var(--dog-ear-size, var(--max-size));

  position: relative;
  height: var(--size);
  width: var(--size);
  max-height: var(--max-size);
  max-width: var(--max-size);
  background-image: linear-gradient(
    var(--dog-ear-rotation, -45deg),
    var(--dog-ear-bg, var(--basic-white)) 50%,
    var(--color-2) 50%
  );
  flex-shrink: 0;

  z-index: 1;

  &.vertical {
    top: 0;
    left: 0;
  }
  &.round-corners {
    border-radius: toRem(10) 0;
  }

  &__icon {
    position: absolute;
    height: 50%;
    bottom: calc(min(var(--size), var(--max-size)) * 0.1);
    right: calc(min(var(--size), var(--max-size)) * 0.2);
    transform: var(--dog-ear-translation, translate(0, 0))
      rotate(var(--dog-ear-icon-rotation, 0deg));
    height: 30%;
    ::v-deep path {
      fill: var(--basic-black);
    }

    &.large {
      transform: var(--dog-ear-translation, translate(0, 0))
        rotate(var(--dog-ear-icon-rotation, 0deg)) scale(1.5);
    }
  }
}

.desktop {
  .dog-ear {
    &.round-corners {
      border-radius: toRem(5) 0;
    }
  }
}
</style>
