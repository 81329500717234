<template>
  <div class="kpi-round">
    <div class="kpi-round__wrapper">
      <p class="kpi-round__wrapper-value">{{ value }}</p>
      <div
        v-for="round in value"
        :key="'round-' + round"
        class="kpi-round__wrapper-round"
        :style="{
          '--diameter': `calc(150px + ${5 * round}px)`,
          '--delay': `${0.3 * round}s`,
        }"
      ></div>
    </div>
    <p
      class="kpi-round__text"
      :style="{ '--margin': `${value * 2.5}px` }"
      v-html="text"
    />
  </div>
</template>

<script>
export default {
  name: "KPIAnimated",
  props: ["value", "text"],
};
</script>

<style lang="scss" scoped>
.kpi-round {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__wrapper {
    text-align: center;
    position: relative;

    &-value {
      position: relative;
      font-family: Spectral;
      font-size: toRem(130);
      font-style: italic;
      font-weight: 800;
      line-height: 120%;
      //   text-align: center;
      //   letter-spacing: toRem(10);
      z-index: 1;
      transform: translateX(toRem(-10));
    }

    &-round {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      border: 1px solid var(--basic-white);
      pointer-events: none;
      animation: animate-round 0.5s forwards cubic-bezier(0.34, 1.56, 0.64, 1);
      animation-delay: var(--delay);
      --opacity: 0;

      &:last-of-type {
        &::after {
          content: "";
          --height: 1rem;
          position: absolute;
          bottom: calc(var(--height) * -1);
          left: 50%;
          transform: translateX(-50%);
          height: var(--height);
          width: 1px;
          background-color: var(--basic-white);
          opacity: var(--opacity);
          transition: opacity 1s ease;
        }
      }
    }
  }

  &__text {
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
    white-space: nowrap;
    margin-top: calc(1rem + var(--margin));
  }
}

@keyframes animate-round {
  0% {
    width: 0;
    height: 0;
    border: 0px;
  }
  100% {
    width: var(--diameter);
    height: var(--diameter);
    --opacity: 1;
  }
}
</style>
