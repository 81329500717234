var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:['constitution-header', { theme: _vm.theme }]},[_vm._l((_vm.content.links),function(link){return _c('router-link',{key:link.pathName,class:[
      'constitution-header__link',
      {
        active:
          _vm.$route.name === link.pathName ||
          (_vm.$route.name === 'theme' && link.pathName === 'themes') ||
          _vm.$route.query.theme === link.pathName,
      } ],attrs:{"to":_vm.theme
        ? { name: 'theme', query: { theme: link.pathName } }
        : { name: link.pathName }}},[_c('span',[_vm._v(" "+_vm._s(link.label)+" ")])])}),(!_vm.theme && _vm.$tvaMq === 'desktop')?_c('button',{staticClass:"constitution-header__share",attrs:{"aria-label":_vm.$t('general.ctas.share')},on:{"click":function () { return _vm.setModalState(!_vm.getModalState); }}},[_c('svgicon',{attrs:{"name":"share","height":"20px","color":"var(--basic-black)"}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }