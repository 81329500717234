<template>
  <button
    :class="[
      'constitution-card',
      { vertical },
      { nonviz: !viz },
      { viz },
      { navButton },
    ]"
    @click="$emit('onCardClick')"
    @mousemove="
      (e) => {
        if (viz) return $emit('onCardMove', e);
      }
    "
    @mouseleave="
      (e) => {
        if (viz) return $emit('onCardLeave', e);
      }
    "
    :disabled="disabled"
  >
    <div class="constitution-card__content">
      <slot></slot>
    </div>

    <transition name="fade">
      <DogEar
        v-if="(withDogEar && !viz) || navButton"
        :vertical="vertical"
        class="constitution-card__dog-ear"
        :gradientColor="gradientColor"
        :showArrow="showArrow"
        :viz="viz"
      />
    </transition>
  </button>
</template>

<script>
import DogEar from "./DogEar.vue";

export default {
  props: {
    vertical: Boolean,
    withDogEar: {
      type: Boolean,
      default: true,
    },
    gradientColor: {
      type: String,
      default: "var(--primary-dark)",
    },
    disabled: Boolean,
    showArrow: {
      type: Boolean,
      default: true,
    },
    viz: {
      type: Boolean,
      default: false,
    },
    navButton: {
      type: Boolean,
      default: false,
    },
  },
  components: { DogEar },
};
</script>

<style lang="scss" scoped>
.constitution-card {
  pointer-events: all;
  
  @include title-h-1;

  background-color: var(--card-color, var(--primary-light));
  border-radius: toRem(10);
  border: toRem(1) solid var(--card-border, var(--primary-dark));
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  text-align: left;

  &:not(:disabled) {
    @include drop-shadow-1;
  }

  &__content {
    padding: toRem(30) toRem(25);
    padding-right: 0;
    align-self: center;
  }

  &.vertical {
    flex-direction: column-reverse;
    align-items: flex-start;

    .constitution-card__dog-ear {
      transform: rotate(180deg);
      top: 0;
      left: 0;
    }
  }
}

.desktop {
  .constitution-card {
    // background-color: var(--basic-white);
    // position: relative;

    &.viz {
      filter: none;
      border: none !important;
      border-radius: toRem(4);
      min-height: fit-content;
      line-height: unset;
      font-size: 0.875rem;

      .constitution-card__content {
        align-self: flex-start;
        padding: toRem(6) toRem(8);
      }
    }

    &.navButton {
      position: relative;

      &:not(.vertical) {
        .constitution-card__dog-ear {
          --size: 1.875rem;
          --max-size: 2.5rem;
          // width: toRem(30);
          // height: toRem(30);
        }
      }
    }

    &.nonviz {
      position: relative;
    }

    &:hover {
      .constitution-card__dog-ear {
        opacity: 1;
      }
    }

    &__content {
      width: 100%;
      padding: toRem(20);
    }

    &__dog-ear {
      // transition: opacity 0.3s ease;
      opacity: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      width: toRem(50);
      height: toRem(50);
    }
  }
}
</style>
