<template>
  <div class="modal-share">
    <div class="modal-share__container">
      <p class="modal-share__container__label">{{ content.label }}</p>
      <p class="modal-share__container__title">{{ content.title }}</p>
      <div class="modal-share__container__links">
        <ShareNetwork
          v-for="link in content.links"
          :key="link.name"
          :title="link.title"
          :description="link.description"
          :url="link.link"
          :network="link.network"
          class="modal-share__container__links__link"
        >
          <div class="modal-share__container__links__link__symbol">
            <svgicon :name="link.icon" height="1.375rem" original></svgicon>
          </div>
          <p class="modal-share__container__links__link__name">
            {{ link.name }}
          </p>
        </ShareNetwork>
      </div>
      <button
        class="modal-share__container__close"
        :aria-label="$t('general.ctas.closeModal')"
        @click="() => setModalState(!getModalState)"
      >
        <svgicon name="close" height="2.125rem"></svgicon>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ModalSocialShare",
  computed: {
    content() {
      return this.$t("share");
    },
    ...mapGetters(["getModalState"]),
  },
  methods: {
    ...mapActions(["setModalState"]),
  },
};
</script>

<style lang="scss">
.modal-share {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: rgba(82, 133, 195, 0.2);
  z-index: 3;

  &__container {
    width: 60%;
    height: 58%;
    margin: auto;
    background-color: var(--basic-white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: toRem(10);
    position: relative;

    &__label {
      font-family: Spectral;
      font-size: 1rem;
      font-style: italic;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      letter-spacing: -0.16px;
      margin-bottom: toRem(15);
    }

    &__title {
      font-family: Spectral;
      font-size: toRem(30);
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 30px */
      letter-spacing: -0.3px;
      margin-bottom: toRem(50);
    }

    &__links {
      display: flex;

      &__link {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:not(:first-of-type) {
          margin-left: toRem(69);
        }

        &__symbol {
          width: toRem(62);
          height: toRem(62);
          border-radius: toRem(10);
          box-shadow: 0px 0px 8px 0px rgba(99, 99, 99, 0.2);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: toRem(20);
        }

        &__name {
          font-family: Inter;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 19.2px */
          letter-spacing: 0.96px;
          text-transform: uppercase;
        }
      }
    }

    &__close {
      position: absolute;
      top: toRem(27);
      right: toRem(26);
    }
  }
}
</style>
