var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'dog-ear__wrapper',
    { viz: _vm.viz, hideShadow: _vm.hideShadow, whitePatchBlur: _vm.whitePatchBlur } ]},[_c('div',{class:[
      'dog-ear',
      {
        'round-corners': _vm.roundCorners,
      },
      { vertical: _vm.vertical } ],style:({ '--color-2': _vm.gradientColor })},[(_vm.showArrow)?_c('svgicon',{class:['dog-ear__icon', { large: _vm.largeArrow }],attrs:{"name":_vm.symbol ? 'close' : _vm.largeArrow ? 'medium-arrow' : 'small-arrow',"color":"r-var(--basic-black)"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }